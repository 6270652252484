<template>
  <!-- <div class="w-1200">
    <div class="recommend-node" v-for="(item, index) in list" :key="index">
      <div class="recommend-node-title">
        <div class="title">
          <div class="title-h1">
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="other" @click="handleOtherClick(item.prodCatId)">
          <span
            >更多<i class="el-icon-arrow-right"></i
            ><i class="el-icon-arrow-right"></i
          ></span>
        </div>
      </div>
      <div class="recommend-node-division blue"></div>
      <div class="recommend-node-bolck">
        <div class="recommend-node-bolck-image">
          <img src="../../assets/store/tmp/recommend-001.png" />
          <div class="tit">{{ item.name }}</div>
        </div>
        <div class="recommend-node-bolck-content">
          <div
            class="recommend-item"
            @click="handleDetailClick(res.storeId, res.spuId)"
            v-for="(res, i) in item.storespulist"
            :key="i"
          >
            <div class="recommend-image">
              <Uimage :src="res.url" />
            </div>
            <div class="recommend-btn">
              <div class="recommend-title">
                {{ res.name }}
              </div>
              <div class="recommend-button">
                详细信息
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="w-1200 content">
    <div class="recommend-node" v-for="(item, index) in list" :key="index">
      <div class="recommend-node-title">
        <div class="title">
          <div class="title-h1">
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="other" @click="handleOtherClick(item.prodCatId)">
          <span
            >更多<i class="el-icon-arrow-right"></i
            ><i class="el-icon-arrow-right"></i
          ></span>
        </div>
      </div>
      <div class="recommend-node-division blue"></div>
      <el-table :data="item.storespulist" style="width: 100%">
<!--        <el-table-column prop="spuNum" label="产品编号"> </el-table-column>-->
<!--        <el-table-column prop="skuNums" label="标物编号"> </el-table-column>-->
        <el-table-column prop="name" label="产品名称">
          <template slot-scope="scope">
            <el-link
              :href="`#/store/${scope.row.storeId}/details/${scope.row.spuId}`"
              target="_blank"
            >
              <span v-html="scope.row.name"></span>
            </el-link>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="price" label="含量"> </el-table-column> -->
        <el-table-column prop="priceSection" label="价格区间">
        </el-table-column>
        <el-table-column label="产品品牌" width="200px">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.brandName }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="inventory" label="库存"> </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getlist(this.$route.params.storeId);
  },
  methods: {
    getlist(e) {
      get('api/storespu/getstorecatlist?storeId=' + e + '').then((res) => {
        this.list = res.data.data;
      });
    },
    handleOtherClick(e) {
      this.$router.push({
        name: 'store_classify',
        params: {
          selectMenu: e,
        },
      });
    },
    handleDetailClick(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.content{
  padding-bottom: 30px;
}
.recommend-node {
  width: 100%;
  margin-top: 30px;
  &-title {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 12px;
    padding: 5px 0px;

    .other.title {
      display: inline-block;
    }

    .other {
      cursor: pointer;
      width: 65px;
      position: relative;

      > span {
        position: absolute;
        bottom: 0px;
      }
    }

    .title {
      width: 100%;
      position: relative;
      padding-left: 25px;

      &:before {
        content: '';
        position: absolute;
        left: 4px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        display: inline-block;
        height: 27px;
        width: 6px;
        background: #fe912a;
      }

      &-h1 {
        display: inline-block;
        font-size: 27px;
        width: 80%;
        overflow: hidden;
      }
    }
  }

  &-division {
    margin: 10px 0px;
    width: 100%;
    height: 3px;

    &.blue {
      background: #4198c5;
    }

    &.violet {
      background: #a687cd;
    }

    &.green {
      background: #88a76e;
    }
  }

  // &-bolck {
  //   height: 597px;
  //   width: 100%;
  //   display: flex;

  //   &-image {
  //     height: 100%;
  //     width: 450px;
  //     position: relative;
  //     > img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //     .tit {
  //       width: 345px;
  //       height: 168px;
  //       position: absolute;
  //       top: 208px;
  //       left: 48px;
  //       text-align: center;
  //       line-height: 168px;
  //       font-size: 27px;
  //       color: #fff;
  //     }
  //   }

  //   &-content {
  //     flex: 1;
  //     display: flex;
  //     flex-wrap: wrap;
  //     overflow: hidden;
  //     .recommend-item:first-child {
  //       height: 295px;
  //       margin-left: 10px;
  //       background: #fff;
  //       position: relative;
  //       .recommend-image {
  //         width: 490px;
  //         height: 295px;
  //         cursor: pointer;
  //         img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }
  //       .recommend-btn {
  //         .recommend-title {
  //           position: absolute;
  //           right: 10px;
  //           top: 20px;
  //           text-align: right;
  //           overflow: hidden;
  //           width: 400px;
  //           cursor: pointer;
  //         }
  //         .recommend-button {
  //           position: absolute;
  //           right: 30px;
  //           top: 50px;
  //           width: 80px;
  //           height: 25px;
  //           line-height: 25px;
  //           text-align: center;
  //           border: 1px solid red;
  //           color: red;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //     .recommend-item:not(:first-child) {
  //       background: #fff;
  //       margin-left: 10px;
  //       width: 240px;
  //       height: 295px;
  //       .recommend-image {
  //         width: 240px;
  //         height: 200px;
  //         cursor: pointer;
  //         img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }
  //       .recommend-title {
  //         overflow: hidden;
  //         margin-top: 10px;
  //         text-align: center;
  //         cursor: pointer;
  //       }
  //       .recommend-button {
  //         width: 80px;
  //         height: 25px;
  //         line-height: 25px;
  //         text-align: center;
  //         border: 1px solid red;
  //         color: red;
  //         margin: auto;
  //         margin-top: 20px;
  //         cursor: pointer;
  //       }
  //     }
  //     .recommend-item:nth-of-type(n + 3) {
  //       margin-top: 10px;
  //     }
  //   }
  // }
}
</style>
