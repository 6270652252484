<template>
  <div class="big-hotsale w-1920">
    <div class="big-hotsale-title w-1200">
      <img src="../../assets/store/big-hotsale.png" />
    </div>
    <div class="big-hotsale-content w-1200">
      <div
        class="big-hotsale-item"
        v-for="(item, index) in list"
        :key="index"
        @click="detail(item.storeId, item.spuId)"
      >
        <div class="big-hotsale-item-image">
          <Uimage :src="item.url" />
        </div>
        <div class="big-hotsale-item-title">
          <div class="title">
            <div class="title-h1">
              <span :title="item.name">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getlist(this.$route.params.storeId);
  },
  methods: {
    //详情
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    getlist(e) {
      get('api/storespu/getstorecxspulist?storeId=' + e + '').then((res) => {
        this.list = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.big-hotsale {
  width: 100%;

  &-title {
    height: 88px;
    width: 100%;
    position: relative;

    > img {
      display: inline-block;
      position: absolute;
      width: 371px;
      height: 45px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      margin: auto;
    }
  }

  &-content {
    width: 1237px;
    margin: auto;
    margin-top: 15px;
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 396px);
    grid-gap: 5px 5px;
  }

  &-item {
    width: 396px;
    height: 210px;
    background: transparent no-repeat;
    background-size: 100% 100%;

    display: flex;

    &-title {
      width: 163px;
      height: 100%;
      position: relative;

      .title {
        position: absolute;
        display: inline-block;
        text-align: center;
        width: 130px;
        height: 100px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        margin: auto;

        &-h1 {
          padding: 10px 0px;
          border-top: 1px solid;
          border-bottom: 1px solid;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 400;
          overflow: hidden;
          max-height: 100%;
          cursor: pointer;
        }
      }
    }

    &-image {
      width: calc(100% - 163px);
      height: 100%;
      position: relative;

      > img {
        display: inline-block;
        width: 165px;
        height: 165px;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        margin: auto;
        cursor: pointer;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9) {
      //background-image: url('../../assets/store/big-hotsale-001.png');
      color: #617e48;
      border: #617e48;
      background: #c3dab1;
    }

    &:nth-of-type(2),
    &:nth-of-type(6),
    &:nth-of-type(7) {
      // background-image: url('../../assets/store/big-hotsale-002.png');
      color: #42748d;
      border: #42748d;
      background: #a1cce3;
    }

    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(8) {
      // background-image: url('../../assets/store/big-hotsale-003.png');
      color: #706180;
      border: #706180;
      background: #d8c9e8;
    }
  }
}
</style>
