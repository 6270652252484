<template>
  <div class="w-1920">
    <header-user></header-user>
    <store-search></store-search>
    <store-Info-chip></store-Info-chip>
    <store-image></store-image>
    <store-menu ref="menu"></store-menu>
    <div class="home">
      <big-hotsale></big-hotsale>
      <store-recommend></store-recommend>
    </div>
    <foot></foot>
    <cart></cart>
  </div>
</template>

<script>
import header_user from '@/components/header';
import store_infochip from './store-Info-chip';
import store_search from './store-search';
import store_image from './store-image';
import store_menu from './store-menu';
import store_classify from './store-classify';
import store_wares from './store-wares';
import baoyou from '@/components/order/baoyou';
import foot from '@/components/foot';
import cart from '@/components/cart';

import big_hotsale from './store-big-hotsale';
import store_recommend from './store-recommend';

export default {
  components: {
    'header-user': header_user,
    'store-Info-chip': store_infochip,
    'store-search': store_search,
    'store-image': store_image,
    'store-menu': store_menu,
    'store-classify': store_classify,
    'store-wares': store_wares,
    baoyou: baoyou,
    foot: foot,
    cart: cart,
    'big-hotsale': big_hotsale,
    'store-recommend': store_recommend,
  },
  created() {
    this.$nextTick(() => {
      this.$refs.menu.setSelectHome();
    });
  },
};
</script>

<style lang="scss" scoped>
.content-padding-bottom {
  padding-bottom: 30px;
}

.home {
  width: 100%;
  min-height: 100%;

  .big-picture {
    width: 100%;
    height: 602px;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .big-classify {
    width: 100%;
    height: 425px;

    &-block {
      width: calc(608px * 2);
      margin: auto;
      margin-top: -97px;
    }

    &-item {
      height: 100%;
      width: 608px;
      display: inline-block;
      position: relative;

      &-content {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 35px;
        height: 62px;
        width: 255px;
        margin: auto;
        background: transparent url('../../assets/store/big-classifybig-ba.png')
          no-repeat;
        background-size: 100% 100%;
        padding-left: 80px;
        padding-right: 139px;
        padding-top: 19px;
        padding-bottom: 19px;

        &:after {
          content: ' ';
          position: absolute;
          display: inline-block;
          width: 41px;
          height: 41px;
          top: 0px;
          bottom: 0px;
          right: 98px;
          margin: auto;
          background: transparent
            url('../../assets/store/big-classifybig-001.png') no-repeat;
          background-size: 100% 100%;
        }

        color: #fff;

        .h1 {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 6px;
        }
      }

      > img {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        box-shadow: 5px 5px 10px #a5a5a59a, -5px -5px 10px #a5a5a59a;
        margin: 5px;
      }
    }
  }
}
</style>
