<template>
  <div class="recommend w-1920">
    <div class="recommend-division">
      <div class="recommend-division-bulge">
        <img src="../../assets/store/division-bulge.png" />
      </div>
    </div>
    <store-recommend-node></store-recommend-node>
    <!-- <div class="recommend-content w-1200">
      <store-recommend-node></store-recommend-node>
      <store-recommend-node></store-recommend-node>
      <store-recommend-node></store-recommend-node>
    </div> -->
  </div>
</template>
<script>
import store_recommend_node from './store-recommend-node';
export default {
  components: {
    'store-recommend-node': store_recommend_node,
  },
};
</script>
<style lang="scss" scoped>
.recommend {
  background: #f5f5f5;
  width: 100%;
  min-height: 100%;

  &-division {
    height: 238px;
    width: 100%;
    margin-top: 36px;
    background: transparent url('../../assets/store/division.jpg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 39px;

    &-bulge {
      width: 79px;
      height: 79px;
      position: absolute;
      bottom: -39.5px;
      left: 0px;
      right: 0px;
      margin: auto;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-content {
    width: 1237px;
    margin: auto;
    padding-bottom: 40px;
  }
}
</style>
